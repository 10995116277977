import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { formatDate, formatDateICU } from '@wix/communities-blog-client-common';
import model from './model';

type CreateController = Parameters<(typeof model)['createController']>[0];

type Metadata = {
  writer: string;
  firstPublishedDate: string;
  timeToRead: number;
  lastPublishedDate: string;
};

const createController = (({ $bind, $widget, flowAPI, $w }) => {
  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {
      setMetadata: (metadata: Metadata) => {
        $w(selectors.writer).text = metadata.writer;

        $w(selectors.publishedDate).text = formatPublishedDate(
          metadata.firstPublishedDate,
          flowAPI,
        );
        $w(selectors.timeToRead).text = formatTimeToRead(metadata.timeToRead);
        $w(selectors.lastPublishedDate).text = formatUpdated(
          metadata.lastPublishedDate,
          flowAPI,
        );
      },
    },
  };
}) satisfies CreateController;

function formatPublishedDate(date: string, flowAPI: PlatformControllerFlowAPI) {
  return formatDate({
    date,
    lng: flowAPI.controllerConfig.wixCodeApi.site.language,
    timezone: flowAPI.controllerConfig.wixCodeApi.site.timezone,
  });
}

function formatTimeToRead(time: number) {
  return `${time} min read`;
}

function formatUpdated(date: string, flowAPI: PlatformControllerFlowAPI) {
  const formattedDate = formatDateICU({
    date,
    lng: flowAPI.controllerConfig.wixCodeApi.site.language,
    rtfFormatter: '',
    timezone: flowAPI.controllerConfig.wixCodeApi.site.timezone,
  });
  return `Updated: ${formattedDate}`;
}

const selectors = {
  writer: '#text1',
  publishedDate: '#text5',
  timeToRead: '#text2',
  lastPublishedDate: '#text3',
} as const;

export type PostMetadataApi = ReturnType<typeof createController>['exports'];

export default model.createController(createController);
