import { I$W, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { NormalizedCategory } from '@wix/communities-blog-client-common';
import { AppDataWithSubjects, PostPageRenderModel } from '../../viewer.app';
import { PostCategoriesApi } from '../Post Categories/viewer.controller';
import { PostCountersApi } from '../Post Counters/viewer.controller';
import { PostMetadataApi } from '../Post Metadata/viewer.controller';
import { PostRatingsApi } from '../Post Ratings/viewer.controller';
import { PostTagsApi } from '../Post Tags/viewer.controller';
import { PostTitleApi } from '../Post Title/viewer.controller';
import model from './model';
import { BlogFacade } from './utils/blog.facade';

// TODO: problem - correct data is shown on 2nd render
export default model.createController((params) => {
  const { $w, $widget, flowAPI } = params;
  const appData = params.appData as AppDataWithSubjects;

  return {
    pageReady: async () => {
      appData?.subjects.postPageRenderModel.subscribe((next) => {
        const { data } = next;
        setPostPageHeader($w, flowAPI, data);
      });

      $widget.fireEvent('widgetLoaded', {});
    },
    exports: model.getExports(),
  };
});

function setPostPageHeader(
  $w: I$W<Record<string, any>>,
  flowAPI: PlatformControllerFlowAPI,
  postPageRenderModel: PostPageRenderModel,
) {
  const blogFacade = new BlogFacade(flowAPI);
  const headerModel = getHeaderData(postPageRenderModel);
  const ui = {
    categories: $w('#categories1') as PostCategoriesApi,
    title: $w('#title1') as PostTitleApi,
    ratings: $w('#ratings1') as PostRatingsApi,
    metadata: $w('#metadata1') as PostMetadataApi,
    counters: $w('#counters1') as PostCountersApi,
    tags: $w('#tags1') as PostTagsApi,
  };

  ui.categories.setCategories(headerModel.categories);
  ui.categories.onCategoryClick((slug) =>
    blogFacade.navigateToCategoryFeed(slug),
  );
  ui.title.setTitle(headerModel.title);
  ui.ratings.setRatings({
    average: headerModel.averageRating,
    total: headerModel.totalRatings,
  });
  ui.metadata.setMetadata({
    writer: headerModel.writer,
    firstPublishedDate: headerModel.firstPublishedDate,
    timeToRead: headerModel.timeToRead,
    lastPublishedDate: headerModel.lastPublishedDate,
  });
  ui.counters.setCounters({
    likeCount: headerModel.likeCount,
    viewCount: headerModel.viewCount,
    commentsCount: headerModel.commentsCount,
  });
  ui.counters.onLikeClick((_event: $w.MouseEvent) => {
    headerModel.isLiked
      ? blogFacade.dislikePost(headerModel.id)
      : blogFacade.likePost(headerModel.id);
  });
  ui.tags.setTags(headerModel.tags);
  ui.tags.onTagClick((slug: string) => blogFacade.navigateToTagFeed(slug));
}

function getHeaderData(postPageRenderModel: PostPageRenderModel) {
  return {
    id: postPageRenderModel.post.id,
    title: postPageRenderModel.post.title || '',
    categories: getPostCategories(
      postPageRenderModel.categories,
      postPageRenderModel.post.categoryIds,
    ),
    averageRating: postPageRenderModel.post.averageRating,
    totalRatings: postPageRenderModel.post.totalRatings,
    writer: postPageRenderModel.post.owner?.name ?? '',
    firstPublishedDate: postPageRenderModel.post.firstPublishedDate,
    timeToRead: postPageRenderModel.post.timeToRead,
    lastPublishedDate: postPageRenderModel.post.lastPublishedDate,
    likeCount: postPageRenderModel.counters.likeCount,
    viewCount: postPageRenderModel.counters.viewCount,
    isLiked: postPageRenderModel.counters.isLiked,
    commentsCount: postPageRenderModel.counters.commentsCount,
    tags: postPageRenderModel.tags,
  };
}

function getPostCategories(
  blogNormalizedCategories: NormalizedCategory[],
  postCategoryIds: string[],
): NormalizedCategory[] {
  return postCategoryIds.reduce((categories, id) => {
    const category = blogNormalizedCategories.find(
      (blogCategory) => blogCategory._id === id,
    );
    if (!category) {
      return categories;
    }
    return [...categories, category];
  }, [] as NormalizedCategory[]);
}
