import model from './model';

type CreateController = Parameters<(typeof model)['createController']>[0];

type Counter = {
  likeCount: number;
  viewCount: number;
  commentsCount: number;
};

const createController = (({ $bind, $widget, flowAPI, $w }) => {
  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {
      setCounters: (counter: Counter) => {
        $w(selectors.likeCount).text = String(counter.likeCount);
        $w(selectors.viewsCount).text = String(counter.viewCount);
        $w(selectors.commentsCount).text = String(counter.commentsCount);
      },
      onLikeClick: (callback: (event: $w.MouseEvent) => void) => {
        $w(selectors.likeButton).onClick(callback);
      },
    },
  };
}) satisfies CreateController;

const selectors = {
  likeCount: '#likeNumber',
  viewsCount: '#viewsNumber',
  commentsCount: '#commentsNumber',
  likeButton: '#likeButtonBlack',
} as const;

export type PostCountersApi = ReturnType<typeof createController>['exports'];

export default model.createController(createController);
