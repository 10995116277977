import model from './model';

type CreateController = Parameters<(typeof model)['createController']>[0];

const createController = (({ $bind, $widget, flowAPI, $w }) => {
  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {
      setTitle: (newTitle: string) => {
        $w(selectors.title).text = newTitle;
      },
    },
  };
}) satisfies CreateController;

const selectors = {
  title: '#title',
} as const;

export type PostTitleApi = ReturnType<typeof createController>['exports'];

export default model.createController(createController);
