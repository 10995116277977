import model from './model';

type CreateController = Parameters<(typeof model)['createController']>[0];

type Rating = { average?: number; total?: number };

const createController = (({ $bind, $widget, flowAPI, $w }) => {
  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {
      setRatings: (rating: Rating) => {
        $w(selectors.ratings).rating = rating.average;
        $w(selectors.ratings).numRatings = rating.total;
      },
    },
  };
}) satisfies CreateController;

const selectors = {
  ratings: '#ratingsDisplay',
} as const;

export type PostRatingsApi = ReturnType<typeof createController>['exports'];

export default model.createController(createController);
