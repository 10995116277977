import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { likePost, unlikePost } from '@wix/ambassador-blog-v3-post/http';
import { blogAppDefId } from '../../../external/common/constants/apps';
import { TPA_PAGE_ID_BLOG } from '../../../external/common/constants/tpa-pages';

export class BlogFacade {
  constructor(private readonly flowAPI: PlatformControllerFlowAPI) {}

  private async makeCategoryFeedUrl(categorySlug: string) {
    const { url } =
      await this.flowAPI.controllerConfig.wixCodeApi.site.getSectionUrl({
        sectionId: TPA_PAGE_ID_BLOG,
        appDefinitionId: blogAppDefId,
      });
    if (url) {
      return `${url}/categories/${categorySlug}`;
    }
  }

  private async makeTagFeedUrl(tag: string) {
    const { url } =
      await this.flowAPI.controllerConfig.wixCodeApi.site.getSectionUrl({
        sectionId: TPA_PAGE_ID_BLOG,
        appDefinitionId: blogAppDefId,
      });

    if (url) {
      return `${url}/tags/${tag}`;
    }
  }

  private async navigateToFeed(url?: string) {
    if (!url) {
      return;
    }
    this.flowAPI.controllerConfig.wixCodeApi.location.to?.(url);
  }

  async navigateToCategoryFeed(slug: string) {
    const url = await this.makeCategoryFeedUrl(slug);
    await this.navigateToFeed(url);
  }

  async navigateToTagFeed(slug: string) {
    const url = await this.makeTagFeedUrl(slug);
    await this.navigateToFeed(url);
  }

  async likePost(postId: string) {
    try {
      await this.flowAPI.httpClient.request(likePost({ postId }));
    } catch (e) {
      throw new Error('Failed to like post.');
    }
  }

  async dislikePost(postId: string) {
    try {
      await this.flowAPI.httpClient.request(unlikePost({ postId }));
    } catch (e) {
      throw new Error('Failed to dislike post.');
    }
  }
}
