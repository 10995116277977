import { NormalizedCategory } from '@wix/communities-blog-client-common';
import model from './model';

type CreateController = Parameters<(typeof model)['createController']>[0];

const createController = (({ $bind, $widget, flowAPI, $w }) => {
  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {
      setCategories: (normalizedCategories: NormalizedCategory[]) => {
        const categories = normalizedCategories.map((cat) => ({
          label: cat?.menuLabel,
          value: cat?.slug,
        }));

        $w(selectors.categories).options = categories;
      },
      onCategoryClick: (callback: (slug: string) => Promise<void>) => {
        $w(selectors.categories).onClick(async (event: $w.MouseEvent) => {
          const categoriesClicked = event.target.value;
          const lastCategoryClicked = categoriesClicked.at(-1);
          if (!lastCategoryClicked) {
            throw new Error('No categories clicked');
          }

          callback(lastCategoryClicked);
        });
      },
    },
  };
}) satisfies CreateController;

export type PostCategoriesApi = ReturnType<typeof createController>['exports'];

const selectors = {
  categories: '#categoriesElement',
} as const;

export default model.createController(createController);
